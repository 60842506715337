import NotFoundLottie from "./Loader/NotFoundLottie";


function NotFound({ title }) {
    return (
        <div className="w-full h-40 flex justify-center items-center ">
            <div className="w-40 text-center">
                <NotFoundLottie />
                <h2 className="text-lg text-indigo-600">{title}</h2>
            </div>
        </div>
    )
};

export default NotFound;