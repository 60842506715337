import {
    SET_REQUIRED_DOCUMENTS, SET_REALTIME_DATA,
    BID_INSERTED, CURRENT_BID_NOT_ACCEPTING
} from "../actions/data";

const initialState = {
    documents: [],
    data: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REQUIRED_DOCUMENTS:
            return {
                ...state,
                documents: action.payload?.length ? action.payload : []
            };
        case SET_REALTIME_DATA: {
            return {
                ...state,
                data: action.payload
            }
        }
        case BID_INSERTED: {
            return {
                ...state,
                data: {
                    ...state.data, current_bid: action.payload
                }
            }
        }
        case 'PERCENTAGE_UPDATED': {
            return {
                ...state,
                data: {
                    ...state.data, increase_percentage: action.payload.increase_percentage
                }
            }
        }
        case CURRENT_BID_NOT_ACCEPTING: {
            let new_buyer_list = [];
            let proceed = state?.data?.buyer?.proceed;
            action.payload.lot_result?.buyers?.forEach(element => {
                state.data.buyer_list?.forEach(item => {
                    if (item.id === element.id) {
                        new_buyer_list = new_buyer_list.concat({ ...element, connected: item.connected })
                    }
                });
                if (element.id === state?.data?.buyer?.id) {
                    proceed = element?.proceed
                }
            });

            return {
                ...state,
                data: {
                    ...state.data, winner: action.payload?.lot_result?.winner, finished: action.payload?.lot_result?.finished,
                    buyer_list: new_buyer_list?.length ? new_buyer_list : state.data.buyer_list,
                    buyer: { ...state.data.buyer, proceed }
                }

            };
        }
        case 'AM_I_CONNECTED': {
            return {
                ...state,
                data: {
                    ...state.data,
                    buyer: { ...state.data?.buyer, connected: action.payload }
                }
            }
        }
        case 'IS_CONNECTED_STAFF': {
            return {
                ...state,
                data: {
                    ...state.data,
                    manager: { ...state.data?.manager, connected: action.payload }
                }
            }
        }
        case 'BUYER_BID_INSERTED': {
            return {
                ...state,
                data: {
                    ...state.data,
                    buyer: action.payload.buyer_id === state.data.buyer.id ? { ...state.data.buyer, ...action.payload } : state.data.buyer,
                    buyer_list: state.data.buyer_list?.map(item => {
                        if (item.id === action.payload.buyer_id) {
                            return { ...item, last_bid_id: action.payload.last_bid_id, proceed: action.payload.proceed }
                        } return item;
                    })
                }

            }
        }
        case 'IS_BUYER_CONNECTED': {
            return {
                ...state,
                data: {
                    ...state.data,
                    buyer_list: state.data.buyer_list?.map(item => {
                        if (item.id === action.payload.id) {
                            return { ...item, connected: action.payload.connected }
                        } return item;
                    })
                }
            }
        }
        default:
            return state;
    }
}

export default reducer;