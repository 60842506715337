import loader from './loader-small.json';
import { useLottie } from "lottie-react";


const SmallLoader = () => {
    const options = {
        animationData: loader,
        loop: true,
        autoplay: true,
    };

    const { View } = useLottie(options);

    return View;
};

export default SmallLoader;