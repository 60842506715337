import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./reducers/auth";
import data from "./reducers/data";
import license from "./reducers/license";
const reducer = combineReducers({
  auth,
  data,
  license,
});

const store = configureStore({
  reducer,
});

export default store;
