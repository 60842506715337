import { useEffect, Suspense, lazy } from "react";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Route, Switch, useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import { LoadUser } from "application/middlewares/auth";
import LoadingData from './components/Loader/LoadingData'
import ScrollToTop from "components/ScrollToTop";
import PrivateRoute from "components/PrivateRoute";
import NotFound from "components/NotFound";
import { RoutesNames } from "pages/License/routes";

const HomePage = lazy(() => import("./pages/Home/Home"));
const AuthPage = lazy(() => import('./pages/AuthPage/index'))

const Auction = lazy(() => import("./pages/Auction"));

const RealTimeAuction = lazy(() => import('./pages/RealTimeAuction/index'));
const UserProfile = lazy(() => import('./pages/Profile/index'));

const Tender = lazy(() => import("./pages/Tender"));
const License = lazy(() => import("./pages/License/index"));



function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        console.log("load user")
        dispatch(LoadUser({ history }));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="static">
            <ScrollToTop />
            <Header />
            <div className="w-full h-screen pt-32">
                <Suspense fallback={<LoadingData loading={true} height={true} />}>
                    <Switch>
                        <Route path="/auctions">
                            <Auction />
                        </Route>
                        <Route path="/tenders">
                            <Tender />
                        </Route>
                        <Route path={RoutesNames.licenses}>
                            <License />
                        </Route>
                        <PrivateRoute
                            path="/profile"
                            redirect="/auth/login"
                            Component={UserProfile}
                        />
                        <PrivateRoute
                            path="/real-time-lots/:lot_id/buyers/:buyer_id"
                            redirect="/auth/login"
                            Component={RealTimeAuction}
                        />

                        <Route path="/auth">
                            <AuthPage />
                        </Route>
                        <Route exact path="/">
                            <HomePage />
                        </Route>
                        <Route path="*">
                            <div className="w-full min-h-500 h-full flex justify-center items-center">
                                <NotFound title="Sahypa Tapylmady" />
                            </div>
                        </Route>
                    </Switch>
                </Suspense>
                <Toaster
                    position="bottom-left"
                    reverseOrder={false}
                />
                <Footer />
            </div>

        </div>
    );
}

export default App;
/* <Route path="/real-time-lots/:lot_id/buyers/:buyer_id">
    <RealTimeAuction />
</Route> */