import {
    logout, userLoading, userLoadFailed, userLoaded, loginSuccess
} from "../actions/auth";
import api from '../api';
import toast from 'react-hot-toast';
import { GetCookie, SetCookie, RemoveCookie } from '../../utils/cookie'
// import Cookies from "js-cookie";

export const Logout = () => async dispatch => {
    try {
        RemoveCookie('refresh_token')
        dispatch(logout());
    } catch (error) {
        dispatch(logout());
        RemoveCookie('refresh_token')
        toast.error('Unknown error');
    }
}

export const LoadUser = ({ history }) => async dispatch => {
    dispatch(userLoading());
    let language = localStorage.getItem('language');
    if (!language) {
        language = 1;
        localStorage.setItem('language', 1);
    }
    let token = GetCookie('refresh_token');
    // let token2 = Cookies.get("refresh_token")
    // console.log("token",token)
    // console.log("token2",token2)    
    try {
        if (token) {
            const response = await api.getApi({ url: '/auctions/api/auth/load-user', token, withCredentials: true });
            dispatch(userLoaded({ ...response.data, language }));
        } else {
            dispatch(userLoadFailed())
        }
    } catch (error) {
        dispatch(userLoadFailed())
    }
}



export const UserLogin = ({ data, setError }) => async dispatch => {
    try {
        const response = await api.postApi({ url: '/auctions/api/auth/users/login', params: data, withCredentials: true, token: "" });
        SetCookie('refresh_token', response.data.refresh_token);
        dispatch(loginSuccess(response.data));
        // history.push('/profile')
    } catch (error) {
        if (error?.response) {
            if (typeof error.response?.data === 'object') {
                let keys = Object.keys(error.response.data);
                keys.map(item => setError(item, { type: 'manual', message: error.response.data[item] }))
            } else {
                toast.error("Operation Not successfull!")
            }
        } else {
            toast.error("Operation Not successfull!")
        }
    }
};


