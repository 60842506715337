import { Link } from 'react-router-dom';
// import LoginIcon from './LoginIcon';
import HeaderTitle from './HeaderTitle';
import { HashLink } from 'react-router-hash-link';
import UserWhite from 'components/lottie/UserWhite';

function LargeScreen({ user, isLogged }) {
    return (
        <header className='z-50 w-full hidden lg:flex flex-row justify-between items-center fixed top-0'>
            <div className="w-2/4 h-2 bg-gradient-to-r from-indigo-500 to-blue-500 z-50 absolute top-0 left-0">
                <div className="relative w-full h-full rounded-sm" >
                    <div style={{ transform: 'rotate(135deg)', right: -20.5, top: -40 }} className="w-8 h-5 mt-5 bg-gradient-to-r from-indigo-500 to-blue-500 absolute rounded-sm"></div>
                </div>
            </div>
            <div className="relative w-2/4 z-40" style={{ marginLeft: -108, height: 115, boxShadow: '10px 17px 26px -7px rgba(0,0,0,0.18)' }} >
                <HeaderTitle />
                <div
                    style={{
                        transform: 'rotate(45deg)',
                        top: -109.5, right: -105.5, width: 170, marginRight: 12, height: 193,
                        boxShadow: '53px 46px 56px -64px rgba(0,0,0,1)'
                    }}
                    className="absolute shadow-lg z-0 rounded-sm bg-secondary">
                    <div className="relative w-full h-full">
                        <div className="absolute top-2 -left-0 w-full h-full z-10">
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative w-2/4 h-full z-30 flex-col">
                <div className='absolute -top-10 -left-8  h-10 bg-secondary w-full'></div>
                {/* <div className='absolute -top-8 right-0 flex h-10 w-full py-1 bg-secondary'>
                    <PhoneView />
                </div> */}
                <div className="absolute -top-10 right-0 h-10 bg-secondary w-full"></div>
                <div className='relative -ml-12 xl:ml-0 -mt-2 flex flex-row justify-between  xl:justify-end  items-center font-normal py-4 text-white shadow-2xl bg-gradient-to-r from-indigo-500 to-blue-500 md:px-2 lg:px-4 xl:px-12 '>
                    <div style={{ boxShadow: '-39px 50px 49px -60px rgba(0,0,0,1)' }} className="absolute bg-gradient-to-r from-indigo-500 to-indigo-500 top-0 left-0 h-full w-32 -ml-24 z-0 "></div>
                    <div className="whitespace-nowrap z-10 px-1 lg:px-2 xl:px-4"> <Link to="/licenses"> Ygtyýarlandyryş {"(Taslama)"}</Link></div>
                    {/* <div className="whitespace-nowrap z-10 px-1 lg:px-2 xl:px-4"> <a target={"blank"} href='https://e.gov.tm/services/onlayn-hyzmatlar/maliye-ulgamy' > <span >Döwlet hyzmatlar portaly</span>  </a></div> */}
                    <div className="border-r-2 border-blue-300 h-4 w-2 xl:mx-4"> </div>
                    <div className="whitespace-nowrap z-10 px-1 lg:px-2 xl:px-4"> <Link to="/auctions"> Bäsleşikli söwdalar </Link></div>
                    <div className="border-r-2 border-blue-300 h-4 w-2 xl:mx-4"> </div>
                    <div className="whitespace-nowrap px-1 lg:px-2 xl:px-4"> <Link to="/tenders">Bäsleşikler</Link> </div>
                    <div className="border-r-2 border-blue-300 h-4 w-2 xl:mx-4"> </div>
                    <div className="whitespace-nowrap px-1 lg:px-2 xl:px-4">
                        <HashLink to={{ pathname: '/', hash: '#qanda' }}>Sorag-jogap</HashLink>
                    </div>
                    <div className="border-r-2 border-blue-300 h-4 w-2 xl:mx-4"> </div>
                    {isLogged === true ?
                        <Link to='/profile/auctions/in-process'>
                            <div className='flex flex-row w-full justify-center items-center'>
                                <p className='uppercase whitespace-nowrap ml-2' >{`${user?.firstname} ${user?.lastname ? user?.lastname[0] : ''}.`}</p>
                            </div>
                        </Link>
                        :
                        <Link to='/auth/login'>
                            <div className='flex flex-row w-full justify-center items-center'>
                                <div className="w-9"><UserWhite /></div>
                                <p className='uppercase whitespace-nowrap ml-2' >Giriş</p>
                            </div>
                        </Link>
                    }
                </div>
            </div>
        </header>
    )
}
export default LargeScreen;