
import { getAuth } from 'application/selectors/auth';
import { useSelector } from 'react-redux';
import LargeHeader from './LargeHeader'
import SmallHeader from './SmallHeader'

export const Header = () => {
    const { user, isLogged } = useSelector(getAuth);
    return (
        <>
            <LargeHeader user={user} isLogged={isLogged} />
            <SmallHeader user={user} isLogged={isLogged} />
        </>
    );
}