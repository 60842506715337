import gerb from './Gerb.svg';
import { Link } from 'react-router-dom';
import { useState } from "react";
import { Burger } from '@mantine/core';
import MobileNav from "./MobileNav";
import UserIndigo from 'components/lottie/UserIndigo';


function SmallHeader({ isLogged, user }) {
    const [open, setOpen] = useState(false);
    return (
        <header className="w-srceen lg:hidden fixed w-full top-0 bg-white z-50">
            <div className={`flex z-40 flex-row justify-center items-center p-1 h-full w-full bg-gradient-to-r from-indigo-500 to-blue-500`}>
                <img src={gerb} className="object-contain w-10 sm:w-14" alt="Türkmenistanyň Gerbi" />
                <Link to='/' className="flex flex-col justify-center items-start ml-2 text-xs sm:text-sm lg:text-base font-medium sm:font-semibold text-white">
                    <h1 className="">TÜRKMENISTANYŇ MALIÝE WE YKDYSADYÝET <br /> MINISTRLIGINIŇ SANLY HYZMATLAR GURŞAWY</h1>
                </Link>
            </div>

            <div className="flex flex-row justify-between items-center w-full bg-white px-2 py-1 shadow-xl text-sm lg:text-base z-50">
                {isLogged === true ?
                    <Link onClick={() => setOpen(false)} to='/profile/auctions/in-process'>
                        <div className='flex flex-row w-full justify-center items-center'>
                            <p className='uppercase whitespace-nowrap ml-2 font-medium text-indigo-700' >{`${user?.firstname} ${user?.lastname ? user?.lastname[0] : ''}.`}</p>
                        </div>
                    </Link>
                    :
                    <Link onClick={() => setOpen(false)} to='/auth/login' className="text-indigo-600 font-medium hover:scale-105 transform duration-300 ease-in-out">
                        <div className='flex flex-row w-full justify-center items-center '>
                            <div className="w-9"><UserIndigo /></div>
                            <p className='uppercase whitespace-nowrap ml-2' >Şahsy otag</p>
                        </div>
                    </Link>
                }
                <Burger
                    opened={open}
                    onClick={() => setOpen(!open)}
                    title="Nawigasiýa açmak"
                    color='rgba(79, 70, 229,1)'
                />
            </div>
            <MobileNav open={open} isLogged={isLogged} closeNav={() => setOpen(false)} />
        </header>
    )
};

export default SmallHeader;




