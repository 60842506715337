import axios from "axios";
import store from "../index";
import { userLoaded } from "../actions/auth";
import { Logout } from "application/middlewares/auth";
import { GetCookie } from "utils/cookie";

const API_BASE_URL =
  process.env.REACT_APP_IS_PRODUCTION === "production"
    ? process.env.REACT_APP_API_BASE_PRODUCTION_URL
    : process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL2 = process.env.REACT_APP_API_BASE_URL2;

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: null,
    "Accept-Language": "tm",
  },
});
export const axiosInstance2 = axios.create({
  baseURL: API_BASE_URL2,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: null,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data === "Unauthorized" &&
      GetCookie("refresh_token")
    ) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${GetCookie("refresh_token")}`,
          "Accept-Language": "tm",
        },
        withCredentials: true,
        //crossDomain: true
      };
      return axiosInstance2
        .get("auctions/api/auth/token-refresh", config)
        .then((response) => {
          axiosInstance2.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          originalRequest.headers.Authorization = `Bearer ${response.data.token}`;
          store.dispatch(userLoaded(response.data));
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          axiosInstance.defaults.headers.Authorization = null;
          originalRequest.headers.Authorization = null;
          store.dispatch(Logout());
          // window.location.href = '/#/auth/login';
          return Promise.reject(error);
        });
    } else {
      axiosInstance.defaults.headers.Authorization = null;
      originalRequest.headers.Authorization = null;
      //store.dispatch(Logout());
      //window.location.href = '/auth/login';
      return Promise.reject(error);
    }
  }
);

axiosInstance2.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data === "Unauthorized" &&
      GetCookie("refresh_token")
    ) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${GetCookie("refresh_token")}`,
          "Accept-Language": "tm",
        },
        withCredentials: true,
        //crossDomain: true
      };
      return axiosInstance2
        .get("auctions/api/auth/token-refresh", config)
        .then((response) => {
          axiosInstance2.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          originalRequest.headers.Authorization = `Bearer ${response.data.token}`;
          store.dispatch(userLoaded(response.data));
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          axiosInstance.defaults.headers.Authorization = null;
          originalRequest.headers.Authorization = null;
          store.dispatch(Logout());
          // window.location.href = '/#/auth/login';
          return Promise.reject(error);
        });
    } else {
      axiosInstance.defaults.headers.Authorization = null;
      originalRequest.headers.Authorization = null;
      //store.dispatch(Logout());
      //window.location.href = '/auth/login';
      return Promise.reject(error);
    }
  }
);

const api = {
  getApi: async ({ url, token, withCredentials, contentType }) => {
    const config = {
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": "tm",
      },
      // timeout: contentType ? 100000 : 10000,
      withCredentials: withCredentials,
    };
    if (url.includes("auction")) {
      const response = await axiosInstance2.get(url, config);
      return response;
    } else {
      const response = await axiosInstance.get(url, config);
      return response;
    }
  },

  updateApi: async ({ url, token, withCredentials, params }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": "tm",
      },
      withCredentials: withCredentials,
    };
    if (url.includes("auction")) {
      const response = await axiosInstance2.put(url, params, config);
      return response;
    } else {
      const response = await axiosInstance.put(url, params, config);
      return response;
    }
  },
  postApi: async ({ url, token, withCredentials, params }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": "tm",
      },
      withCredentials: withCredentials,
    };
    if (url.includes("auction")) {
      const response = await axiosInstance2.post(url, params, config);
      return response;
    } else {
      const response = await axiosInstance.post(url, params, config);
      return response;
    }
  },
  deleteApi: async ({ url, token, data, withCredentials }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "Accept-Language": "tm",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: withCredentials,
      data: data,
    };
    if (url.includes("auction")) {
      const response = await axiosInstance2.delete(url, config);
      return response;
    } else {
      const response = await axiosInstance.delete(url, config);
      return response;
    }
  },
  uploadPhoto: async ({ url, token, withCredentials, formData }) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "Accept-Language": "tm",
      },
      // timeout: 50000,
      withCredentials: withCredentials,
    };
    if (url.includes("auction")) {
      const response = await axiosInstance2.post(url, formData, config);
      return response;
    } else {
      const response = await axiosInstance2.post(url, formData, config);
      return response;
    }
  },
};

export default api;
