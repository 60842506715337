import SmallLoader from "./SmallLoader";

function LoadingData({ loading, height = false }) {
    return (
        <div className={loading === true ? `${height === true ? 'min-h-500 absolute' : ''} w-full h-full static flex justify-center items-center` : 'hidden'}>
            <div className={`${height ? 'absolute w-full h-full bg-blue-50 opacity-30' : 'hidden'}`}></div>
            <div className="w-40">
                <SmallLoader />
            </div>
        </div>
    );
};

export default LoadingData;