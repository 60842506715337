import {
    LOGIN_SUCCESS, LOGOUT, USER_LOADING, USER_LOAD_FAILED, USER_LOADED, REGISTER_SUCCESS
} from "../actions/auth";

const initialState = {
    token: "",
    isLogged: false,
    isLoading: true,
    user: {},
    language: 1,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.data,
                isLoading: false,
                isLogged: true,
                spaces: action.payload.data.restaurants
            };
        case USER_LOADED:
            return {
                ...state,
                ...action.payload,
                isLogged: true,
                isLoading: false
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLogged: false,
                isLoading: false,
                user: { ...state.user, id: action.payload.id }
            }
        case LOGOUT:
            return {
                ...state,
                token: "",
                isLogged: false,
                isLoading: false,
                user: {},
            }
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case USER_LOAD_FAILED:
            return {
                ...state,
                token: "",
                isLogged: false,
                isLoading: false,
                user: {},
            }
        default:
            return state;
    }
}

export default reducer;