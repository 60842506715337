import cookie from 'js-cookie';

export const SetCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value);
  }
};

export const GetCookie = (name) => {
  console.log("process.browser",process.browser)
  if (process.browser) {
    return cookie.get(name)
  }return null;
}

export const RemoveCookie = (name)=>{
  return cookie.remove(name);
}