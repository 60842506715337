import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Logo from "components/RealTimeCard/img/Logo";
import { Transition } from '@mantine/core';


const MobileNav = ({ open, closeNav, isLogged }) => {
    return (
        <Transition mounted={open} transition="fade" duration={250} timingFunction="ease">
            {(styles) =>
                <nav style={styles}>
                    <div style={{
                        WebkitBoxShadow: 'inset 3px 93px 84px -141px rgba(0,0,0,1)',
                        MozBoxShadow: 'inset 3px 93px 84px -141px rgba(0,0,0,1)',
                        boxShadow: 'inset 3px 93px 84px -141px rgba(0,0,0,1)',
                    }} className={`${open ? 'z-40 relative w-full h-screen' : 'hidden'} lg:hidden flex flex-col w-full h-full bg-white pt-12`}>
                        <div className="p-0 text-center text-xl sm:text-2xl flex w-full flex-col justify-start items-center -mt-4">
                            <Link onClick={closeNav} to="/" className="text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105">
                                Baş sahypa
                            </Link>
                            <Link onClick={closeNav} to="/auctions" className="text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105"> Bäsleşikli söwdalar </Link>
                            <Link onClick={closeNav} to="/tenders" className="text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105">Bäsleşikler</Link>
                            <Link onClick={closeNav} to="/licenses" className="text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105">Ygtyýarnamalar {"(Taslama)"}</Link>
                            <a href="https://e.fineconomic.gov.tm/registry" target="_blank" onClick={closeNav} rel="noreferrer" className='text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105'>
                                At bellige almak
                            </a>
                            <a href="https://balykgorag.gov.tm/e-hyzmat" target="_blank" onClick={closeNav} rel="noreferrer" className='text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105'>
                                Döwlet balyk goraýyş we suwuň bioserişdelerine gözegçilik
                            </a>
                            <a href="https://invest.gov.tm/project" target="_blank" onClick={closeNav} rel="noreferrer" className='text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105'>
                                Maýa goýum taslamalary
                            </a>
                            {/* <a href="https://e.fineconomic.gov.tm/old/#esalgyt" target="_blank" onClick={closeNav} rel="noreferrer" className='text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105'>
                        Elektron Salgyt
                    </a> */}
                            {/* <a href="https://e.fineconomic.gov.tm/old/#eyuridiki" target="_blank" onClick={closeNav} rel="noreferrer" className='text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105'>
                        Ýuridiki şahslar
                    </a> */}
                            <HashLink onClick={closeNav} className="text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105" to={{ pathname: '/', hash: '#qanda' }}>Sorag-jogap</HashLink>
                            {isLogged === true ?
                                <Link onClick={closeNav} to="/auth/logout" className="z-40 flex flex-row justify-center items-center text-center my-2 md:my-3 hover:text-blue-500 transform ease-in-out duration-300 hover:scale-105">

                                    <span className="z-30">Çykmak</span>
                                </Link>
                                : null
                            }
                        </div>
                        <div className="flex justify-center absolute bottom-28 left-0 right-0 mx-auto">
                            <div className="relative w-full ">
                                <div className="footer__contacts text-gray-500">
                                    <h3 className="footer__contact-title text-base">HABARLAŞMAK:</h3>
                                    {/* <p className="text-sm">Elektron Salgyt: 39-45-22; 57.</p> */}
                                    {/* <p className="text-sm">Ýuridiki şahslar: 39-46-10</p> */}
                                    <p className="text-sm">Bäsleşikli söwdalar: 39-46-09</p>
                                    <p className="text-sm">Bäsleşikler: 39-46-38</p>
                                    <p className="text-sm">Goşmaça soraglar: 39-43-34.</p>
                                    <p className="text-sm">744028 Aşgabat ş. Arçabil şaýoly 156</p>
                                </div>
                                <Logo className="absolute -top-12 -right-44 sm:-right-24 md:-right-12 w-80 opacity-30" />
                            </div>
                        </div>
                    </div>
                </nav>
            }
        </Transition>
    );
};

export default MobileNav;