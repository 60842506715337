export const SET_REQUIRED_DOCUMENTS = 'SET_REQUIRED_DOCUMENTS';
export const setReqiuredDocuments = (data) => ({
    type: SET_REQUIRED_DOCUMENTS,
    payload: data
});

export const SET_REALTIME_DATA = 'SET_REALTIME_DATA';
export const setRealtimeData = (data) => ({
    type: SET_REALTIME_DATA,
    payload: data
});

export const BID_INSERTED = 'BID_INSERTED';
export const bidInserted = (data) => ({
    type: BID_INSERTED,
    payload: data
});

export const CURRENT_BID_NOT_ACCEPTING = 'CURRENT_BID_NOT_ACCEPTING'
export const currentBidNotAccepting = (data) => ({
    type: CURRENT_BID_NOT_ACCEPTING,
    payload: data
});