import gerb from './Gerb.svg';
import { Link } from 'react-router-dom';

function HeaderTitle() {
    return (
        <div className="absolute top-0 left-0 w-full h-full z-50 bg-secondary">
            <div className={`flex flex-row justify-center items-center h-full w-full pl-28`}>
                <img src={gerb} className="object-contain" alt="Türkmenistanyň Gerbi" />
                <Link to='/' className="flex flex-col justify-center items-start ml-2 text-xs md:text-sm lg:text-base font-semibold text-indigo-600">
                    <h1 >TÜRKMENISTANYŇ MALIÝE WE YKDYSADYÝET MINISTRLIGINIŇ <br /> SANLY HYZMATLAR GURŞAWY</h1>
                </Link>
            </div>
        </div>
    );
};

export default HeaderTitle;