const baseLicenseApiUrl = `${process.env.REACT_APP_API_BASE_URL2}/license/api/licensing`;

const ApiRoutesNames = {
  licenseCategories: `${baseLicenseApiUrl}/license-categories`,
  helperData: `${baseLicenseApiUrl}/helper-data`,
  licenseTypes: `${baseLicenseApiUrl}/license-types`,
  checkIsApplied: `${baseLicenseApiUrl}/check/is-applied`,
  apply: `${baseLicenseApiUrl}/apply`,
  applicationSubmit: `${baseLicenseApiUrl}/customers`,
};

const RoutesNames = {
  profile: "/profile/licenses",
  auth_global: "/auth/login",
  licenseTypes: "/licenses/:category_id/items",
  licenses: "/licenses",
  applicationForm:"/licenses/:license_typeId/apply-customers/:customerId/application",
  documentUploadForm:"/licenses/:license_typeId/apply-customers/:customerId/documents"
};

export { ApiRoutesNames, RoutesNames, baseLicenseApiUrl };
