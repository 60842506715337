import { getIsLoading, getIsLogged } from '../application/selectors/auth'
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingData from './Loader/LoadingData';

const PrivateRoute = ({ Component, redirect, ...rest }) => {
  const location = useLocation();
  const isLoading = useSelector(getIsLoading);
  const isLogged = useSelector(getIsLogged);
  return (<Route {...rest}
    render={props => {
      if (isLoading) {
        return <LoadingData />;
      } else if (isLoading === true && isLogged === false) {
        return <LoadingData />;
      } else if (isLoading === false && isLogged === false) {
        return <Redirect to={{ pathname: redirect, state: { from: location } }} />;
      } else if (isLogged === false) {
        return <Redirect to={{ pathname: redirect, state: { from: location } }} />;
      } else {
        return <Component {...props} />;
      }
    }}
  />
  );
}

export default PrivateRoute;
