//Images
import book from "./book.svg";
import { Link, useLocation } from "react-router-dom";
import "./footer.css";
import { HashLink } from 'react-router-hash-link';

export const Footer = () => {
    const location = useLocation();
    return (
        <footer className={`${location.pathname.search('profile') === 1 ? 'hidden' : 'footer px-2 sm:px-12'} `}>
            <div className="flex flex-col lg:flex-row container xl:px-24 justify-between w-full  items-center lg:items-start ">
                <div className="footer__title px-3 w-full flex justify-center items-center">
                    <img
                        className="footer__book-img"
                        src={book}
                        alt="Minfin Logo"
                    />
                    <h3 className="text-base lg:text-lg">
                        TÜRKMENISTANYŇ MALIÝE WE YKDYSADYÝET MINISTRLIGINIŇ
                        SANLY HYZMATLAR GURŞAWY
                    </h3>
                </div>
                <div className="flex flex-wrap lg:flex-col  justify-center items-start max-w-md text-blue-500  py-5 lg:py-0">
                    <Link to="/auctions" className="mx-2 hover:text-blue-800">Bäsleşikli söwdalar</Link>
                    <Link to="/tenders" className="mx-2 hover:text-blue-800">Bäsleşikler</Link>
                    {/* <a href="https://e.fineconomic.gov.tm/old/#esalgyt" target="_blank" rel="noreferrer" className='mx-2'>
                        Elektron Salgyt
                    </a>
                    <a href="https://e.fineconomic.gov.tm/old/#eyuridiki" target="_blank" rel="noreferrer" className='mx-2'>
                        Ýuridiki Şahslar
                    </a> */}
                    <a href="https://e.fineconomic.gov.tm/registry" target="_blank" rel="noreferrer" className='mx-2 hover:text-blue-800'>
                        At bellige almak
                    </a>
                    {/* <a href="https://balykgorag.gov.tm/" target="_blank" rel="noreferrer" className='mx-2 hover:text-blue-800'>
                        Döwlet balyk goraýyş we suwuň <br/> bioserişdelerine gözegçilik
                    </a> */}
                    <HashLink className="mx-2 hover:text-blue-800" to={{ pathname: '/', hash: '#qanda' }}>Sorag-jogap</HashLink>
                </div>
                <div className="footer__contacts">
                    <h3 className="footer__contact-title">HABARLAŞMAK:</h3>
                    {/* <p>Elektron Salgyt: 39-45-22; 57.</p> */}
                    <p>Bäsleşikli söwdalar: 39-46-09</p>
                    <p>Bäsleşikler: 39-46-38</p>
                    <p>Goşmaça soraglar: 39-43-34.</p>
                    <p>Elektron poçta: info@fineconomic.gov.tm</p>
                    <p>744028 Aşgabat ş. Arçabil şaýoly 156</p>

                    {/* <p>Sorag-jogap üçin: 39-46-10</p> */}
                </div>
            </div>
            <div className="footer__copyright">
                Copyright &#169; 2021. Ähli hukuklar goraldy.
            </div>
        </footer>
    );
};


//<footer className={`${location.pathname.search('profile') === 1 ? 'hidden' : 'footer px-2 sm:px-12'} `}>